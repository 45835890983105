
/* Definition Lists
------------------------------------------------------------------- */
[id=page] {
    dt {
        margin-bottom: 0.1rem;
    }

    dd {
        margin-bottom: 1rem;
        margin-left: 2rem;
    }
}

[id=blog-index] {
    dt {
        margin: 0;
    }

    dd {
        margin: 0;
    }
}
