/* Article customization
------------------------------------------------------- */
article {
    counter-reset: cnt_fig cnt_table cnt_vid cnt_gal;
    
    h1 {
	margin-top: 3rem;
    }

    h2 {
	margin-top: 2.5rem;
    }

    h3 {
	margin-top: 2rem;
    }

    header {
	h1, h2, h3 {
	    margin-top: 0;
	}
    }

    figcaption {
        margin-top: 0.8rem;
	    text-align: center;
	    font-style: italic;
	    line-height: 1.2;
    }

    figcaption::before {
	    counter-increment: cnt_fig;
	    content: "Abbildung " counter(cnt_fig) ": ";
    }

    table {
	    caption {
            margin-top: 0.8rem;
	        caption-side: bottom;
	        font-weight: normal;
	        font-style: italic;
	        line-height: 1.2;
	    }
	    caption::before {
	        counter-increment: cnt_table;
	        content: "Tabelle " counter(cnt_table) ": ";
	    }
    }

    videocaption {
        margin-top: 0.8rem;
	    text-align: center;
	    font-style: italic;
	    line-height: 1.2;
    }

    videocaption::before {
	    counter-increment: cnt_vid;
	    content: "Video " counter(cnt_vid) ": ";
    }

    div.gallery {
        padding-bottom: 1em;
    }
    
    gallerycaption {
        width: 100%;
        margin-top: 0.8rem;
	    text-align: center;
	    font-style: italic;
	    line-height: 1.2;
        display: inline-block;
    }

    gallerycaption::before {
	    counter-increment: cnt_gal;
	    content: "Gallerie " counter(cnt_gal) ": ";
    }


    ul.bibliography {
	    list-style-type: decimal;
    }

    div.image-container {
        padding-bottom: 1.25rem;
        text-align: center;

        img {
            max-height: 700px;
        }
    }

    div.video-container {
        padding-bottom: 1.25rem;
        text-align: center;

        video {
            width: 100%;
            max-height: 700px;
        }       
    }

    mjx-container {
        min-width: unset !important;
        .MJX-TEX {
            @include breakpoint(small) {
                font-size: 0.8rem !important;
                mjx-mtable {
                    text-align: left;
                }
            }
            @include breakpoint(medium) {
                font-size: 0.9rem !important;
                mjx-mtable {
                    text-align: center;
                }
            }
            @include breakpoint(large) {
                font-size: 1.0rem !important;
                mjx-mtable {
                    text-align: center;
                }
            }
        }
    }
}
