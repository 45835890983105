// Components for javascript downloads.
// xy-grid
// typography
// float classes
// visibility classes
// buttons
// menu
// top bar
// accordion
// off canvas
// orbit
// sticky


// --- Foundation components. ---
// --- Dependencies ---
@import 'foundation/vendor/normalize';
@import 'foundation/vendor/sassy-lists/stylesheets/helpers/missing-dependencies';
@import 'foundation/vendor/sassy-lists/stylesheets/helpers/true';
@import 'foundation/vendor/sassy-lists/stylesheets/functions/contain';
@import 'foundation/vendor/sassy-lists/stylesheets/functions/purge';
@import 'foundation/vendor/sassy-lists/stylesheets/functions/remove';
@import 'foundation/vendor/sassy-lists/stylesheets/functions/replace';
@import 'foundation/vendor/sassy-lists/stylesheets/functions/to-list';

// Import default settings
@import 'foundation/global';
@import 'foundation/settings';

// Import custom settings
@import 'theme/foundation_custom_settings';

// Utilities
//@import 'foundation/util/util';

// Global styles
@import 'foundation/typography/typography';

// General layout
@import 'foundation/components/table';

// Grid
@import "foundation/xy-grid/xy-grid";

// Navigation
@import "foundation/components/accordion-menu";
@import "foundation/components/menu";
@import "foundation/components/menu-icon";
@import "foundation/components/off-canvas";
@import "foundation/components/sticky";
@import "foundation/components/title-bar";
@import "foundation/components/top-bar";

// Containers
@import "foundation/components/accordion";
@import "foundation/components/callout";
@import "foundation/components/card";

// Orbit slider
@import "foundation/components/orbit";
@import "foundation/components/button";
@import "foundation/vendor/motion-ui/motion-ui";

// Helpers
@import 'foundation/components/float';
@import 'foundation/components/flex';
@import 'foundation/components/visibility';


// Global Styles
@include foundation-global-styles;
@include foundation-typography;

// Grids
@include foundation-xy-grid-classes;


// Generic Components
@include foundation-button;
//@include foundation-button-group;
//@include foundation-close-button;
//@include foundation-label;
//@include foundation-progress-bar;
//@include foundation-slider;
//@include foundation-switch;
@include foundation-table;

// Basic Components
//@include foundation-badge;
//@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
//@include foundation-dropdown;
//@include foundation-pagination;
//@include foundation-tooltip;

// Containers
@include foundation-accordion;
//@include foundation-media-object;
@include foundation-orbit;
//@include foundation-responsive-embed;
//@include foundation-tabs;
//@include foundation-thumbnail;

// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
//@include foundation-drilldown-menu;
//@include foundation-dropdown-menu;

// Layout components
@include foundation-off-canvas;
//@include foundation-reveal;
@include foundation-sticky;
@include foundation-title-bar;
@include foundation-top-bar;

// Parts needed for the orbit slider.
@include motion-ui-transitions;
@include motion-ui-animations;

// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;

// --- Customization ---
@import "theme/_article_layout";
@import "theme/_navigation";
@import "theme/_blog_layout";
@import "theme/_list_customization";
@import "theme/_footer";
