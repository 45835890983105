// 1. Global
// ---------
// My corporate identity colors.
$ci-light-blue:  #65b9cb;   
$ci-dirty-yellow: #beb161;	
$ci-grey: #7c7f80;

$foundation-palette: (
  primary: $ci-light_blue,
  secondary: $ci-grey,
  success: #3adb76,
  warning: #ffae00,
  alert: #cc4b37,
);

@include add-foundation-colors;

/* Grey Scale
------------------------------------------------------------------- */
$grey-1: #E4E4E4;
$grey-2: #D7D7D7;
$grey-3: #CBCBCB;
$grey-4: #BEBEBE;
$grey-5: #A4A4A4;
$grey-6: #979797;
$grey-7: #8B8B8B;
$grey-8: #7E7E7E;
$grey-9: #646464;
$grey-10: #575757;
$grey-11: #4B4B4B;
$grey-12: #3E3E3E;
$grey-13: #313131;
$grey-14: #242424;
$grey-15: #171717;
$grey-16: #0B0B0B;



// 4. Base Typography
// ------------------
$header-styles: (
  small: (
    'h1': ('font-size': 20),
    'h2': ('font-size': 19),
    'h3': ('font-size': 18),
    'h4': ('font-size': 17),
    'h5': ('font-size': 16),
    'h6': ('font-size': 15),
  ),
  medium: (
    'h1': ('font-size': 40),
    'h2': ('font-size': 31),
    'h3': ('font-size': 25),
    'h4': ('font-size': 20),
    'h5': ('font-size': 16),
    'h6': ('font-size': 14),
  ),
);

$font-family-sans-serif: "LatoWeb", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-serif: "Volkhov", Georgia, Times, serif;
$font-family-monospace: "Lucida Console", Monaco, monospace;
$font-family-bold: "LatoWebHeavy";

$body-font-family: $font-family-sans-serif;
$body-font-weight: normal;
$body-font-style: normal;


$anchor-color: $primary-color;
$anchor-color-hover: scale-color($anchor-color, $lightness: -14%);

// 11. Button
// ----------
$button-background: $primary-color;
$button-background-hover: scale-color($button-background, $lightness: -15%);
$button-palette: $foundation-palette;
$button-transition: background-color 0.25s ease-out, color 0.25s ease-out;


// 25. Off-canvas
// --------------
$offcanvas-background: rgba($black, 0.9);
$offcanvas-exit-background: rgba($ci-dirty-yellow, 0.75);

// 55. Top Bar
// -----------
$topbar-background: $white;
$topbar-submenu-background: $topbar-background;


/* Custom Footer Variables
------------------------------------------------------------------- */
$footer-bg: $grey-3;
$footer-color: $grey-16;
$footer-link-color: $anchor-color;

